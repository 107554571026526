.card {
    background-color: white;
    border-radius: 8px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    padding: 20px;
    margin-bottom: 20px;
}


@media (max-width: 375px) {
    h3.ms-1 {
        font-size: 16px;
        /* Adjust font size */
        margin-bottom: 15px;
        /* Adjust margin */
    }

    .d-flex {
        flex-wrap: wrap;
        /* Allow wrapping of elements */
    }

    .ms-2 {
        margin-left: 15px;
        /* Adjust margin for smaller screens */
    }

    .me-5 {
        margin-right: 0;
        /* Remove or reduce right margin */
    }

    /* Reorder the elements */
    h1.vehicle-no {
        font-size: 300px;
        order: 0;

        /* Keep the vehicle number on top */
    }

    .form-button-expo {
        width: 100%;
        /* Make the button take the full width */
        /* Add some space above the button */
    }
}